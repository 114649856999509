/*
    Ocford Blue: #06101e,
    Sky Blue Crayola: #5ADBFF,
    Slate Grey: #637786,
    Snow: #FFFAFA
*/

$navColor: snow;
$introColor: snow;
$headerColor: #4FB286;
$secondaryColor: #5ADBFF;
$paragraphColor: #637786;
$backgroundColor: #06101e;

html {
    background-color: $backgroundColor;
    scrollbar-width: none;
    scroll-behavior: smooth;

    &::-webkit-scrollbar {
        display: none;
    }
}

@mixin for-phone-only {
    @media (max-width: 599px) { @content; }
}
  
@mixin for-medium-screens {
    @media (min-width: 600px) { @content; }
}

@mixin for-tablet-landscape-up {
    @media (min-width: 900px) { @content; }
}

@mixin for-desktop-up {
    @media (min-width: 1200px) { @content; }
}

@mixin for-phone-landscape {
    @media (max-height: 420px) { @content; }
}