@import '../../global.scss';

.menu-button {
    position: fixed;
    bottom: 0;
    right: 0;

    @include for-tablet-landscape-up {
        display: none;
    }
}