@import '../../global.scss';

.navbar {
    position: relative;
    height: 60px;
    width: 100vw;
    background-color: rgba(black, 0);
    overflow: hidden;
    transition: all 1.5s ease;
    margin-bottom: 19vh;

    @include for-medium-screens {
        margin-bottom: 10vh;
    }

    @include for-tablet-landscape-up {
        margin-bottom: 6rem;
    }

    @include for-desktop-up {
        margin-bottom: 19vh;
    }

    @include for-phone-landscape {
        margin-bottom: 6vh;
    }

    .wrapper {
        height: 60px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .left {
            .logocontainer {
                display: flex;
                align-items: center;
                margin-left: 20px;
                margin-right: 110px;

                .logo {
                    height: 50px;

                    @include for-desktop-up {
                        height: 65px;
                    }
                }
            }
        }

        .middle {
            visibility: hidden;

            @include for-tablet-landscape-up {
                visibility: visible;
                display: flex;
                justify-content: center;
                margin: auto;
                width: 50vw;
                overflow: hidden;

                .navlink {
                    display: flex;
                    align-items: center;
                    margin: 0 20px;
                    flex-shrink: 0;

                    .link {
                        color: $navColor;
                        padding: 3px;
                        text-decoration: none;
                        font-size: 1rem;

                        @include for-tablet-landscape-up {
                            font-size: 1.2rem;
                        }

                        @include for-desktop-up {
                            font-size: 1.5rem;
                        }
                    }

                    .link:hover {
                        color: $headerColor;
                        cursor: pointer;
                    }
                }
            }
        }
            
        .right {
            display: flex;

            .itemContainer {                
                display: flex;
                align-items: center;
                margin: 0 20px;

                .icon {
                    font-size: 20px;
                    color: $navColor;

                    @include for-desktop-up {
                        font-size: 27px;
                    }
                }

                .github {
                    :hover {
                        color: rgb(189, 189, 189);
                    }
                }

                .linkedin {
                    :hover {
                        color: #0072b1;
                    }
                }

                .youtube {
                    :hover {
                        color: red;
                    }
                }
            }
        }
    }
}