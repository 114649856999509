@import "./global.scss";

.App {
    background-color: $backgroundColor;
    overflow-x: hidden;
    overflow-y: scroll;
    scroll-behavior: smooth;
    width: 100vw;
    scrollbar-width: none;

    .scroll {
        display: none;

        @include for-tablet-landscape-up {
            display: inline;
        }
    }

    &::-webkit-scrollbar {
        display: none;
    }
}