@import "../../global.scss";

.works {
    display: flex;
    width: 100vw;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    .work-header {
        color: $secondaryColor;
        margin-top: 1.3rem;
        font-size: 1.5rem;

        @include for-medium-screens {
            font-size: 2rem;
        }

        @include for-tablet-landscape-up {
            margin-top: 1.5rem;
        }

        @include for-phone-landscape {
            font-size: 1.5rem;
        }
    }

    .tabs {
        margin-top: 1.3rem;
        font-size: 1rem;

        @include for-medium-screens {
            font-size: 2rem;
        }

        @include for-tablet-landscape-up {
            margin-top: 1.5rem;
        }

        @include for-phone-landscape {
            font-size: 1.5rem;
        }

        .tab {
            color: $headerColor;
            padding: 10px;
            background-color: transparent;
            border-radius: 5px;
            line-height: 1rem;
            font-size: 1.5rem;
            cursor: pointer;
        }
    }
}