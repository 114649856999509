@import '../../global.scss';

.about {
    .text-box {
        width: 95vw;
        display: grid;
        padding-top: 3rem;
        grid-template-columns: 95vw;
        align-items: center;
        margin: auto;
        margin-bottom: 5rem;
        grid-row-gap: 0;
        overflow: hidden;

        > * {
            margin: 0 auto 2.5rem;

            @include for-medium-screens {
                margin: 0 auto;
            }
        }

        @include for-medium-screens {
            grid-row-gap: 3rem;
        }

        @include for-tablet-landscape-up {
            grid-template-columns: 47vw 48vw;
            grid-template-rows: 12rem repeat(4, 1fr);
            grid-row-gap: 2rem;
        }

        @include for-desktop-up {
            grid-row-gap: 2rem;
        }

        @include for-phone-landscape {
            grid-template-rows: auto;
            grid-template-columns: 95vw;
            grid-row-gap: 3rem;
        }

        .title {
            color: $secondaryColor;
            width: 7rem;
            text-align: center;
            box-shadow: 0 0 5px 5px;
            font-family: 'Caveat', cursive;
            font-weight: 700;
            padding: 0.5rem 6.5rem;
            font-family: 'Caveat', cursive;
            font-size: 3rem;

            @include for-tablet-landscape-up {
                font-size: 4rem;
                width: 20vw;
            }

            @include for-desktop-up {
                width: 25vw;
            }

            @include for-phone-landscape {
                font-size: 3rem;
            } 
        }

        .about-box {
            @include for-tablet-landscape-up {
                grid-row-start: 1;
                grid-row-end: 2;
                grid-column-start: 1;
            }

            @include for-phone-landscape {
                grid-row-start: 1;
                grid-row-end: 2;
                grid-column-start: 1;
            }
        }

        .headline {
            padding: 0 2rem;

            @include for-tablet-landscape-up {
                grid-row-start: 1;
                grid-row-end: 2;
                grid-column-start: 2;
            }

            @include for-phone-landscape {
                grid-row-start: 2;
                grid-row-end: 3;
                grid-column-start: 1;
            }

            h3 {
                color: $introColor;

                 @include for-tablet-landscape-up {
                    font-size: 1.2rem;
                 }

                 @include for-desktop-up {
                     font-size: 1.6rem;
                 }
            }
        }

        .detailed {
            padding: 0 2rem;

            @include for-tablet-landscape-up {
                grid-row-start: 2;
                grid-row-end: 6;
                grid-column-start: 1;
                grid-column-end: 2;
            }

            @include for-phone-landscape {
                grid-row-start: 3;
                grid-row-end: 4;
                grid-column-start: 1;
            }

            p {
                color: $paragraphColor;
                font-size: 1.7rem;
                margin-bottom: 4rem;

                @include for-tablet-landscape-up {
                    text-indent: 25px;
                }

                .spotify {
                    color: #1DB954;
                }

                .link {
                    cursor: pointer;

                    &:hover {
                        color: white;
                    }
                }
            }
            
        }

        .skills-title-box {
            @include for-tablet-landscape-up {
                grid-column-start: 2;
                grid-row-start: 2;
                grid-row-end: 3;
            }

            @include for-phone-landscape {
                grid-row-start: 4;
                grid-row-end: 5;
                grid-column-start: 1;
            }
        }

        .skills-box {
            @include for-tablet-landscape-up {
                grid-row-start: 3;
                grid-row-end: 5;
            }

            @include for-desktop-up {
                grid-row-start: 3;
                grid-row-end: 6;
            }

            @include for-phone-landscape {
                grid-row-start: 5;
                grid-row-end: 6;
                grid-column-start: 1;
            }
        }
    }
}