@import "../../global.scss";

.w-project {
    display: flex;
    width: 100vw;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 1.3rem;
    margin-bottom: 1.5rem;
    padding-bottom: 2rem;

    a {
        width: 80%;
        aspect-ratio: 16 / 9;
        border: 5px solid $paragraphColor;
        margin-bottom: 1rem;
        
        @include for-medium-screens {
            width: 70%;
            border-width: 7px;
        }

        @include for-desktop-up {
            width: 45%;
            border-width: 9px;
        }

        img {
            width: 100%;
            height: 100%;
        }
    }

    .title {
        font-size: 2rem;
        margin-bottom: 1rem;
        color: white;

        @include for-medium-screens {
            font-size: 2.5rem;
        }
    }

    .description {
        width: 90%;
        font-size: 1.2rem;
        color: $paragraphColor;
        text-align: center;

        @include for-medium-screens {
            font-size: 1.5rem;
        }
    }
}