@import '../../global.scss';

.intro {
    position: relative;
    display: flex;
    flex-direction: column;
    align-content: center;
    min-height: 100vh;

    .text-box{
        line-height: 6rem;

        h2 {
            font-size: 2.5rem;
            line-height: 3rem;
            text-align: center;
            color: $introColor;

            @include for-medium-screens {
                font-size: 4rem;
                line-height: 5rem;
            }

            @include for-phone-landscape {
                font-size: 2.5rem;
                line-height: 3rem;
            }
        }

        .name {
            color: $secondaryColor;
            font-weight: bold;
            font-size: 3rem;
            padding-top: 20px;
            padding-bottom: 20px;
            font-family: 'Courgette', cursive;
            font-weight: 700;

            @include for-medium-screens {
                font-size: 4rem;
            }

            @include for-tablet-landscape-up {
                font-size: 5rem;
             }

             @include for-phone-landscape {
                 font-size: 3.5rem;
             }
        }

        .about-me {
            width: 100vw;
    
            @include for-desktop-up {
                margin-top: 30px;
            }

            @include for-phone-landscape {
                margin-top: 30px;
            }
    
            ul {
                list-style: none;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                line-height: 2.5rem;
    
                @include for-tablet-landscape-up {
                    flex-direction: row;
                }
    
                li {
                    font-size: 2rem;
                    margin: 0 15px;
                    color: $paragraphColor;
                    
                    @include for-tablet-landscape-up {
                        font-size: 2.2rem;

                        &:nth-child(1)::after {
                            content: "|";
                            padding-left: 30px;
                        }
        
                        &:nth-child(2)::after {
                            content: "|";
                            padding-left: 30px;
                        }
                    }
                }
            }
        }
    }
}