@import '../../global.scss';

.container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    @include for-tablet-landscape-up {
        grid-template-columns: repeat(4, 1fr);
        row-gap: 1.5rem;
    }

    img {
        height: 5.5rem;
        width: 6.4rem;
        padding: 10px 10px;

        @include for-tablet-landscape-up {
            height: 4.8rem;
            width: 5.8rem;
        }

        @include for-desktop-up {
            height: 5.7rem;
            width: 7.7rem;
        }

        @include for-phone-landscape {
            height: 5rem;
            width: 7rem;
        }
    }
}